/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500&display=swap');
@import 'ngx-toastr/toastr';
@import '~bootstrap/scss/bootstrap';



:root {
  --text-color:#555;
  --white-color:#fff;
  --primary-color: #004c78;;
  --secondary-color: #2292cf;
}

html,
body,
.main {
  height: 100%;
  margin: 0;
  font-family: 'Poppins', sans-serif!important;
  color:var(--text-color);

}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 10px 0;
  color: var(--text-color);
}

p, ul {
  margin: 0;
}

a,
a:visited,
a:hover {
  text-decoration: none!important;
  color: var(--text-color);
}

p,
td,
a,
button,
select,
label,
input,
li,
textarea,
span, td, th {
  font-size: 13px !important;
  // color: var(--text-color);
  letter-spacing: 0.2px;
}
textarea{resize: none; }
p,
li {
  line-height: 24px !important;
}
input[type=checkbox] {height: auto!important;}
.bold{font-weight: 700;}
ul li{list-style: none; }
label{
  margin-bottom: 3px;
}
b, strong, .font-weight-bold{font-weight: 500;}
input, select{height: 40px!important;}
.clr_text{
  color: var(--primary-color);
}
.clr_text2{
  color: var(--secondary-color);
}
table thead th{border-bottom: 2px solid transparent;}
.f-weight-medium{font-weight: 500;}
.w-87{width: 100%;}
.w-60{width: 60%;}
::placeholder{
  font-size: 10px;
  color:#999!important;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff!important;
  text-decoration: none;
  background-color: var(--secondary-color);
  transition:all 0.3s ;
}
.cust_align{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
button{height: 40px; width: 150px;}
.cust_btn{
  background-color: var(--secondary-color)!important;
}
 .cust_btn2{
  background-color: var(--primary-color)!important;
}
.btn_cyan-outline, .btn_cyan {
  line-height: 27px;
  border: 1px solid var(--secondary-color);
  // height: 44px!important;
  font-size: 13px!important;
  width: 150px;
  color: var(--primary-color);
  transition: .3s all ease;
  border-radius: 0.25rem;;
}
.btn_cyan-outline:hover {
  background-color: var(--secondary-color);
  color: #fff;
}
.btn_cyan-outline i{
  font-size: 20px;
  position: relative;
  top: 2px;
  color: var(--secondary-color);
  left: -8px;
  width: 20px;
}
.btn_cyan-outline:hover i {
  color: #fff;
}
.btn_cyan{background-color: var(--primary-color)!important; border: 1px solid var(--primary-color); color: #fff!important;}

.f-10{
  font-size: 10px!important;
}
.f-11{
  font-size: 11px!important;
}
.page{border: 1px solid #ddd; border-radius: 2px;}
.f-12{
  font-size: 12px!important;
}
.bg-shade{background: #f0f0f0;}
table th{
  border-top: 1px solid #fff!important;
  font-weight: 500;
  color: #222!important;
}
table td{vertical-align: middle!important;}
.top_bar{
  width: 90%;
  position: fixed;
  background: #fff;
  z-index: 1;
}
.main_wrapper{
  display: flex;
}
.sidebar{
  width: 10%;
  height: 100vh;
  padding: 1rem;
  border-right: 1px solid #ddd;
  position: fixed;

  // box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.content-wrapper{
  width: 100%;
  padding-left: 10%;
}
.main_content{
  padding: 2rem;
  margin-top: 5rem;
  position: relative;
  display: block;
    // z-index: -1;

}
.bg-light_cyan {
  background-color: #d0e9f0;
}
.search_group{
  position: relative;
}
.search_group input{
  height: 40px;
  padding-left: 3rem;
  border-right: 4px solid var(--secondary-color);
}
.search_group i{
  position: absolute;
  top: 0;
  width: 50px;
  height: 42px;
  text-align: center;
  line-height: 42px;
  color: var(--primary-color);
}
.search_bar button{
  width: 150px;
  background: transparent;
}
.dropdown .filter_btn{
  text-align: left;
  padding-left: 2.5rem;
  height: 40px;
}
.dropdown .filter_btn i{
  position: absolute;
  left: 15px;
  font-size: 1.2rem;
  color: var(--secondary-color);
}
.dropdown .filter_btn.dropdown-toggle::after {position: absolute; right: 10px; top: 17px; font-size: 1.2rem; border-top: 0.3em solid #c3c1c1;}
.patients-list_table table tr td i{font-size: 1.2rem;}
.actions .filter_btn{height: 30px; width: 80px; position: relative;}
.actions .dropdown .filter_btn i {top: 4.5px;}
.actions .dropdown .filter_btn.dropdown-toggle::after{top: 12px;}
.actions .dropdown-menu a, .filter_button .dropdown-menu a{color: var(--text-color);}
.actions .dropdown-menu a:hover, .filter_button .dropdown-menu a:hover{color: var(--secondary-color);}
.patient_info{
  width: 100%;
  display: flex;
}
.patient_info .item{width: 33.3%; margin: 0 auto;}
.patient_info .item ul li span{ line-height: 28px;}
.patient_info .item ul li span:first-child{
  color: var(--primary-color);
  font-weight: 400;

}
.patient_info .item ul li span:last-child{
  // font-weight: 400;
}
.patient_info .item ul li span:last-child{padding-left: 5px;}
.patient_info .item:nth-child(2), .patient_info .item:last-child{
  padding: 0 3rem;
  border-left: 1px solid #ddd;
}
.tab-content .container{max-width: 100%; padding: 0;}
.cust_tabs .nav-tabs .nav-link{font-weight: 500;padding: .5rem 0rem;
  margin: 0 2rem;}
.cust_tabs .nav-tabs .nav-item:first-child .nav-link{margin-left: 0;}
.cust_tabs .nav-tabs .nav-item.show .nav-link, .cust_tabs .nav-tabs .nav-link.active {
  font-weight: 500;
  color: var(--primary-color);
  background-color: #fff;
  border-color: #fff #fff #fff;
  border-bottom: 2px solid var(--secondary-color);
}
// .cust_tabs .nav-tabs .nav-link:focus, .cust_tabs .nav-tabs .nav-link:hover{border-color:2px solid #fff #fff #fff;}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  border-color: #fff #fff #fff;
  color: var(--primary-color);
}

//
tr.hide-table-padding td {
  padding: 0;
  }

  .expand-button {
    position: relative;
  }

  .accordion-toggle .expand-button:after
  {
    position: absolute;
    left:.75rem;
    top: 50%;
    transform: translate(0, -50%);
    content: '-';
  }
  .accordion-toggle.collapsed .expand-button:after
  {
    content: '+';
  }

.cust_card{
  width: 100%;
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 12px;
    justify-content: space-between;
}

.card_right i{
  width: 40px;
  height: 40px;
  font-size: 1.2rem;
  border-radius: 20px;
  text-align: center;
  line-height: 40px;
  color: #fff;
  background-color: #2292cf;
}
.card_1 .card_right i{background-color: #fff4d1; color: orange;}
.card_2 .card_right i{background-color: #c0ffd8; color: #00c34b;}
.card_3 .card_right i{background-color: #f0fedf; color: #a6d074;}
.card_4 .card_right i{background-color: #e6fafb; color: #67ccd3;}
.analysis_list table thead{
  background-color: #e6fafb;
}
.analysis_list .table thead th{border-bottom: 2px solid #fff;
}
.analysis_list .table tbody tr td:nth-child(2) a {
  font-weight: 500 !important;
  // color: #222 !important;
}
.analysis_list .table tbody tr td:nth-child(2) a:hover{color: var(--primary-color)!important;}
.analysis_list .table tbody tr td:last-child{width: 27%!important;}
.check_success i{color: #00c34b;}
.check_default i{color: #c9c9c9;}
.actions .check_success span, .actions .check_default span{
  font-size: 12px!important;
}
.cancel_btn{
  color: var(--primary-color);
  background: transparent;
  border: 1px solid transparent;
  transition: 0.3s all ease;
}
.btn_cyan:hover{color: #fff; background-color: var(--secondary-color)!important; border: 1px solid var(--secondary-color);}
.cancel_btn:hover{background: #888; border: 1px solid #888;}
.patients-list_table .page-item.active .page-link {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  color: #fff!important;
}
// .patients-list_table .page-item.active .page-link {color:inherit!important;}
/* Importing Bootstrap SCSS file. */


.multiselect-dropdown .dropdown-btn .selected-item:hover {
  box-shadow: 15px 15px transparent!important;
}
.multiselect-dropdown .dropdown-btn .selected-item a{
  font-size: 14px !important;
  width: 20px !important;
  display: inline-block;
  height: 15px;
  color: var(--text-color)!important;
  text-align: center;
  background: #fff;
  background-color: rgb(255, 255, 255);
  border-radius: 2px;
  transition: all 0.3s ease;
  line-height: 10px;
  margin-left: 10px !important;
}
.multiselect-dropdown .dropdown-btn .selected-item a:hover{font-weight: 700;}
.border_line{border-bottom: 1px solid #ddd;}
.snv_info .form-group{margin-bottom: 0;}
.w-adjust{flex: 0 0 20%;}
.drug_title{
  font-weight: 500;
  color: var(--primary-color)!important;
}
.drug_title:hover{
  color: var(--secondary-color)!important;
}
.cursor_action{cursor: pointer;}

// toggles switch css
.toggle_main .switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 22px;
}

.toggle_main .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle_main .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.toggle_main .slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

.toggle_main input:checked + .slider {
  background-color: var(--primary-color);
}

.toggle_main input:focus + .slider {
  box-shadow: 0 0 1px var(--primary-color);
}

.toggle_main input:checked + .slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.slected_rows {display: flex; justify-content: end;}
.toggle_main{width: 148px;}
.toggle_main .left, .toggle_main .right{position: absolute; top: 2px;}
.toggle_main .left{left: -60px;}
.toggle_main .right{right: -100px;}
.template_width select{width: 200px; text-align: center;}
